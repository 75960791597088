<template>
    <div>
        <v-btn prepend-icon="mdi-delete" variant class="delete-btn" @click="dashboardDelete()">
              Delete
        </v-btn>
        <v-dialog transition="dialog-top-transition" width="auto" v-model="showDeleteConfirmation">
            <v-alert title="Delete Dashboard" text="Are you sure you want to delete dashboard ?">
                <v-btn variant="tonal" @click="closeDeleteConfirmation">Cancel</v-btn>
                <v-btn color="error" text @click="confirmDeleteDashboard" :loading="loading">Delete</v-btn>
            </v-alert>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "DeleteDashboard",
    data() {
        return {
            deleteDashboard: require("../../../assets/Images/Dashboard/Delete.png"),
            showDeleteConfirmation: false,
            loading: false,
        }
    },
    props: {
        dashboard_id: {
            type: String,
        },
    },
    methods: {
        dashboardDelete() {
            this.showDeleteConfirmation = true; // Show the confirmation alert
        },

        closeDeleteConfirmation() {
            this.showDeleteConfirmation = false; // Hide the alert
        },
        confirmDeleteDashboard() {
            const tenantId = localStorage.getItem("tenantId");
            const dashboardId = this.dashboard_id;

            this.loading = true;

            axios
                .delete(
                    process.env.VUE_APP_API_URL +
                    "public/api/v1/dashboards/" +
                    dashboardId,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                            "X-TenantID": tenantId,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        this.loading = false;
                        this.showDeleteConfirmation = false;
                        localStorage.removeItem("lastVisitedDashboard")
                        this.$router.push({
                            name: "Main",
                        });
                    } else {
                        console.error("Error deleting dashboard:", response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.code === "ECONNABORTED") {
                        this.timeoutMessage = `Request timeout. Try again`;
                    }
                    this.loading = false;
                });
        },
    }
}
</script>