<template>
  <div>
    <div class="pl-5 pt-3">
      <v-switch v-if="dlComposition && dlComposition.length > 0 && !loading" v-model="toggleFraction" color="info" :label="toggleFraction ? 'Mole %' : 'Mass %'"
        @change="toggleFractionFunction"></v-switch>
    </div>
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <GasCompComponentChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
      <v-col cols="12" md="6">
        <GasCompPressureChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
    </v-row>
    <div v-if="dlComposition && dlComposition.length > 0 && !loading">
      <v-chip label style="width: 100%; font-weight: 600;">
        Liberated Gas Compositions
      </v-chip>

      <div class="table-wrap">
        <table class="data-table">
          <tr>
            <td>Pressure (psia)</td>
            <td v-for="component in uniqueStepPressure" :key="component">
              {{ component }}
            </td>
          </tr>
          <tr>
            <td>Component</td>
            <td v-for="component in uniqueStepPressure" :key="component">
              {{ toggleFraction ? 'Mole%' : 'Mass%' }}
            </td>
          </tr>
          <tbody>
            <tr v-for="component in uniqueFluidComponents" :key="component">
              <td>{{ component }}</td>
              <td v-for="(entry) in dlComposition.filter(entry => entry.fluidComponentReference === component)"
                :key="entry.stepPressure">
                {{ toggleFraction ? (entry.vapor_MoleFraction || entry.vapor_MoleFraction === 0 ?
        entry.vapor_MoleFraction : "-") : (entry.vapor_MassFraction || entry.vapor_MassFraction === 0 ?
          entry.vapor_MassFraction : "-") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="!loading && !dlComposition" class="text-center pt-15">
      <h4>Table data is not available.</h4>
    </div>
    <div v-else-if="loading">
      <screen-loader></screen-loader>
    </div>
  </div>
</template>

<script>
import GasCompComponentChart from "./Charts/GasCompComponentChart.vue";
import GasCompPressureChart from "./Charts/GasCompPressureChart.vue";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import axios from "axios";

export default {
  name: "GasCompData",
  components: {
    GasCompComponentChart,
    GasCompPressureChart,
    ScreenLoader,
  },
  data() {
    return {
      dlComposition: [],
      tenantId: localStorage.getItem("tenantId"),
      loading: true,
      toggleFraction: true,
    };
  },
  computed: {
    uniqueFluidComponents() {
      if(!this.dlComposition){
        return;
      }
      return [...new Set(this.dlComposition.map(entry => entry.fluidComponentReference))];
    },
    uniqueStepPressure() {
      if(!this.dlComposition){
        return;
      }
      return [...new Set(this.dlComposition.map(entry => entry.stepPressure))];
    },
    getChartData() {
      if(!this.dlComposition){
        return;
      }
      // Filter dlComposition data based on toggleFraction
      if (this.toggleFraction) {
        // Filter for Mole%
        return this.dlComposition.map(entry => ({
          fluidComponentReference: entry.fluidComponentReference,
          stepPressure: entry.stepPressure,
          vapor_MoleFraction: entry.vapor_MoleFraction
        }));
      } else {
        // Filter for Mass%
        return this.dlComposition.map(entry => ({
          fluidComponentReference: entry.fluidComponentReference,
          stepPressure: entry.stepPressure,
          vapor_MassFraction: entry.vapor_MassFraction
        }));
      }
    }
  },
  methods: {
    toggleFractionFunction() {
      this.dlComposition = [];
      this.getTableData();
    },
    getTableData() {
      var sampleId = localStorage.getItem("sampleId");
      var dlId = localStorage.getItem("testId");
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/dltests/${dlId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dlComposition = response.data.data.dltest.dlComposition;
            if (!this.dlComposition) {
              return;
            }
            else {
              // const sortByKeys = response.data.data.dltest.dlComposition.map(item => item[sortByArray])[0].flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));
              const sortByArray = this.toggleFraction ? 'sortByMole' : 'sortByMass';
              let sortByKeys = [];
              if (response.data.data.dltest.dlComposition[0][sortByArray] && response.data.data.dltest.dlComposition[0][sortByArray].length > 0) {
                sortByKeys = response.data.data.dltest.dlComposition.map(item => item[sortByArray]).flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));
              } else {
                // Fallback to using serverItems for headers and display names
                // Filter out keys that contain _UOM
                sortByKeys = Object.keys(this.serverItems || {}).filter(
                  (key) => !key.includes("_UOM")
                ).map(
                  (key) => key.charAt(0).toLowerCase() + key.slice(1)
                );
              }

              const availableKeys = new Set(
                sortByKeys.filter((key) =>
                  this.dlComposition.some((item) => key in item)
                )
              );

              const allKeys = sortByKeys.filter((key) => availableKeys.has(key));
              const allUniqueKeys = Array.from(allKeys);

              const displayNames = [];

              Promise.all(
                allUniqueKeys.map(async (key) => {
                  const capitalizedKey =
                    key.charAt(0).toUpperCase() + key.slice(1);
                  const displayName = await this.$store.dispatch(
                    "metaData/getDisplayName",
                    { tableName: "DLComposition", fieldName: capitalizedKey }
                  );
                  displayNames.push(displayName);
                })
              );
              this.loading = false;

              return response.data.data.dltest;
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getTableData();
  },
};
</script>