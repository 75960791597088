<template>
  <div class="data-management">
    <ScreenLoader v-if="showLoader == true"></ScreenLoader>
    <v-form v-else>
      <div class="dashbaord-top-wrap mr-3">
        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="showLoader == true">
            <ScreenLoader></ScreenLoader>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0 dash-input" v-else-if="this.dashboardsArr.length != 0">
            <v-select v-model="dashboard" :items="dashboardName" item-text="Name"
              @update:modelValue="() => changeDashboard()" placeholder="Select saved dashboard">
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center justify-center" v-else>
            <div class=" timeout">
              <div class="text-center">
                {{ timeoutMessage }}
                <v-icon flat @click="getSavedDashboards()" color="blue-darken-3" icon="mdi-reload"
                  size="large"></v-icon>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 mt-3 mb-1 edit-delete-dashboard" v-if="dashboardAccessLevel == 'private'">
            <v-btn prepend-icon="mdi-pencil" variant class="save-btn" v-if="showEditBtn" @click="openEdit">
              Edit
            </v-btn>
            <v-btn prepend-icon="mdi-minus-circle" v-if="showCancelBtn" variant class="user-dropdown"
              @click="closeEdit">
              Cancel
            </v-btn>
            <DeleteDashboard :dashboard_id="dashboard_id" />
          </v-col>
        </v-row>

        <dashboard-charts class="mt-2" v-if="showDashboardLoader"></dashboard-charts>
        <h6 class="main-heading mb-2 text-capitalize" v-else-if="showTitle">
          {{ dashboard }}
        </h6>
        <v-row no-gutters class="my-3" v-else-if="editName">
          <v-col cols="12" class="pa-0 pb-2 pl-md-0 py-md-0">
            <v-text-field v-model="dashboard_name" required placeholder="Edit dashboard name" hide-details
              outlined></v-text-field>
          </v-col>
        </v-row>
      </div>
      <ChartsData v-if="isDataFetched && showCharts" :chartTypes="chartTypes" />
      <!-- <DashboardChartsData v-if="isDataFetched && showCharts" :chartTypes="chartTypes" :dashboard_id="dashboard_id" /> -->
      <v-row no-gutters v-if="showCharts == false">
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <v-select clearable v-model="chart_one" item-value="Name" item-text="Name" placeholder="Select saved chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 0)">
          </v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 px-md-3">
          <v-select clearable v-model="chart_two" item-value="Name" item-text="Name" placeholder="Select saved chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 1)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <v-select clearable v-model="chart_three" item-value="Name" item-text="Name" placeholder="Select saved chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 2)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 px-md-3">
          <v-select clearable v-model="chart_four" item-value="Name" item-text="Name" placeholder="Select saved chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 3)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <v-select clearable v-model="chart_five" item-value="Name" item-text="Name" placeholder="Select saved chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 4)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 px-md-3">
          <v-select clearable v-model="chart_six" item-value="Name" item-text="Name" :items="chartTypesName"
            placeholder="Select saved chart" @update:modelValue="(value) => chartTypeChange(value, 5)"></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pa-0 mb-3" v-if="showSaveBtn">
          <v-btn block flat class="save-btn text-none" @click="updateDashboard" :loading="loading"
            :disabled="isUpdateDisabled">Update dashboard</v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 px-md-3 mb-3" v-if="showChartInput && [
      chart_one,
      chart_two,
      chart_three,
      chart_four,
      chart_five,
      chart_six
    ].filter(chart => chart != null && chart != '').length < 6">
          <v-btn prepend-icon="mdi-plus" block flat class="user-dropdown text-none" @click="openStudioDataDialog">
            Add your desired chart</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="showStudioDataDialog" width="100vw">
      <v-card>
        <div class="modal-header header-div">
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" flat icon size="x-small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <StudioData @save-chart="handleChartSavedStudio" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import api from "../../../axiosInterceptor"
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import StudioData from '../FluidsDataStudio/StudioData.vue';
import ChartsData from "./ChartsData.vue";
// import DashboardChartsData from "./DashboardChartsData.vue";
import DeleteDashboard from "./DeleteDashboard"
import DashboardCharts from '../Loaders/DashboardCharts.vue';

export default {
  name: "BoardSelected",
  components: {
    ScreenLoader,
    StudioData,
    ChartsData,
    // DashboardChartsData,
    DeleteDashboard,
    DashboardCharts,
  },
  data() {
    return {
      showLoader: true,
      dashboardName: [],
      dashboardsArr: [],
      tenantId: localStorage.getItem("tenantId"),
      dashboard: null,
      chartTypes: [],
      chartTypesName: [],
      chart_one: null,
      chart_two: null,
      chart_three: null,
      chart_four: null,
      chart_five: null,
      chart_six: null,
      dashboard_id: "",
      newDashboard: [],
      showChartInput: false,
      showSaveBtn: false,
      showTitle: true,
      editName: false,
      dashboard_name: "",
      selectedChartIds: [],
      loading: false,
      isDataFetched: false,
      showCharts: true,
      showStudioDataDialog: false,
      showAlert: false,
      errorMessage: "",

      timeoutMessage: "",
      showDashboardLoader: true,
      showEditBtn: true,
      showCancelBtn: false,
      dashboardAccessLevel: "",
    };
  },
  computed: {
    isUpdateDisabled() {
      return (
        !this.dashboard_name ||
        !(
          this.chart_one ||
          this.chart_two ||
          this.chart_three ||
          this.chart_four ||
          this.chart_five ||
          this.chart_six
        )
      );
    },
  },
  methods: {
    openStudioDataDialog() {
      this.showStudioDataDialog = true;
    },
    closeModal() {
      this.showStudioDataDialog = false;
    },
    handleChartSavedStudio(newChart) {
      this.getSavedCharts();
      // Add the new chart to chartTypes and chartTypesName
      this.chartTypes.push(newChart);
      this.chartTypesName.push(newChart.name);

      // Find the first empty chart slot and set the new chart there
      if (!this.chart_one) {
        this.chart_one = newChart.name;
      } else if (!this.chart_two) {
        this.chart_two = newChart.name;
      } else if (!this.chart_three) {
        this.chart_three = newChart.name;
      } else if (!this.chart_four) {
        this.chart_four = newChart.name;
      } else if (!this.chart_five) {
        this.chart_five = newChart.name;
      } else if (!this.chart_six) {
        this.chart_six = newChart.name;
      }
      this.closeModal();
    },
    getSavedCharts() {
      this.showLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      axios
        .get(process.env.VUE_APP_API_URL + "public/api/v1/charts", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.charts;
            this.chartTypesName = response.data.data.charts.map(
              (chart) => chart.Name
            );
            this.showChartInput = true;
            this.showSaveBtn = true;
            this.showTitle = false;
            this.editName = true;
            this.showCharts = false;
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },

    chartTypeChange(selectedChartName, chartIndex) {
      const selectedChart = this.chartTypes.find(
        (chart) => chart.Name === selectedChartName
      );

      if (selectedChart) {
        this.updateSelectedChartIds[chartIndex] = selectedChart.ID;
      }
    },
    openEdit() {
      this.getSavedCharts();
      this.showEditBtn = false;
      this.showCancelBtn = true;
    },
    closeEdit() {
      this.showChartInput = false;
      this.showSaveBtn = false;
      this.showTitle = true;
      this.editName = false;
      this.showCharts = true;
      this.getSavedDashboards();
      this.fetchData();
      this.showCancelBtn = false;
      this.showEditBtn = true
    },
    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards.map(
              (dashboard) => dashboard.name
            );
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },

    fetchData() {
      this.showLoader = true;
      this.dashboardName = this.$route.params.name.replace(/-/g, " ");
      const dashboardString = JSON.stringify(this.dashboardName);
      this.showDashboardLoader = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.chartTypes = dashboardData.Charts;
            this.chartTypesName = this.chartTypes.map((chart) => chart.Name);
            this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            this.chart_one = this.chartTypesName[0];
            this.chart_two = this.chartTypesName[1];
            this.chart_three = this.chartTypesName[2];
            this.chart_four = this.chartTypesName[3];
            this.chart_five = this.chartTypesName[4];
            this.chart_six = this.chartTypesName[5];
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showDashboardLoader = false;
        });
    },

    fetchDataOnChange(newDashboardName) {
      this.showLoader = true;
      this.newDashboard = newDashboardName.replace(/-/g, " ");
      const dashboardString = JSON.stringify(this.newDashboard);
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            this.showEditBtn = true;
            this.showCancelBtn = false;
            this.dashboard = response.data.data.dashboards[0].name;
            this.dashboard_name = response.data.data.dashboards[0].name;
            this.dashboard_id = response.data.data.dashboards[0].ID;
            this.dashboardAccessLevel = response.data.data.dashboards[0].AccessLevel;
            this.chartTypes = response.data.data.dashboards[0].Charts;
            this.chartTypesName = this.chartTypes.map((chart) => chart.Name);
            this.chart_one = this.chartTypesName[0];
            this.chart_two = this.chartTypesName[1];
            this.chart_three = this.chartTypesName[2];
            this.chart_four = this.chartTypesName[3];
            this.chart_five = this.chartTypesName[4];
            this.chart_six = this.chartTypesName[5];
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    changeDashboard() {
      const selectedDashboard = this.dashboardsArr.find(
        (dashboard) => dashboard.name === this.dashboard
      );

      if (selectedDashboard.name) {
        const newDashboardName = selectedDashboard.name.replace(/\s/g, "-");
        this.$router.push({
          name: "Board",
          params: { name: newDashboardName },
        });
        this.fetchDataOnChange(newDashboardName);
      }
    },

    updateDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;

      this.selectedChartIds = [
        this.chart_one,
        this.chart_two,
        this.chart_three,
        this.chart_four,
        this.chart_five,
        this.chart_six
      ].map(chartName => {
        const chart = this.chartTypes.find(chart => chart.Name === chartName);
        return chart ? chart.ID : null;
      }).filter(id => id !== null);

      this.loading = true;

      const requestBody = {
        Data: {
          dashboard: {
            name: this.dashboard_name,
            chartsID: this.selectedChartIds,
            AccessLevel: "private",
          },
        },
      };

      axios
        .put(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + dashboardId,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getSavedDashboards();
            this.loading = false;
            this.isDataFetched = true;
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            const updateDashboardName = this.dashboard_name.replace(/\s/g, "-");
            this.$router.push({
              name: "Board",
              params: { name: updateDashboardName },
            });
            this.fetchDataOnChange(updateDashboardName);
          }
        })
        .catch((error) => {
          this.showAlert = true;
          this.errorMessage = error.response.data.error;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSavedDashboards();
    this.fetchData();
  },
};
</script>