<template>
  <div class="data-management">
    <v-row no-gutters class="pr-4 mb-2">
      <div class="toggle-btn-div">
        <div class="label-toggle">
          <span>Expand all samples</span>
        </div>
        <div>
          <v-switch v-model="expandAllSamples" color="info"></v-switch>
        </div>
        <div class="label-toggle">
          <span>Show all samples</span>
        </div>
        <div>
          <v-switch v-model="showAllSamples" color="info"></v-switch>
        </div>
      </div>
      <v-col cols="12" class="pt-4">
        <TabularLoaders v-if="showLoader" />
        <v-expansion-panels class="mb-4 pl-3" multiple v-model="expandedPanels" variant="accordion"
          v-else-if="!showLoader && samples.length > 0">
          <v-expansion-panel v-for="(sample, sampleIndex) in samples" :key="sampleIndex" :value="sample.ID">
            <div class="title-pdf-div">
              <v-expansion-panel-title expand-icon="mdi-chevron-right" collapse-icon="mdi-chevron-down">
                <div class="well-data">
                  <div class="well-content">
                    <img :src="sample_image" alt="Panel Image" class="mr-2" width="44" height="44" />
                    <div>
                      <div>
                        <div>Sample ID: {{ sample.ID }}</div>
                        <div class="sample-type">
                          <div>Well: {{ sample.location.well }}</div>
                          <div>Sample Date: {{ sample.sampleDate }}</div>
                          <div>Sample Type: {{ sample.sampleKind }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-title>
              <div v-if="pdfLoaders[sample.ID]" class="pdf-div">
                <img :src="pdf_loader_image" alt="loader Image" width="30" height="30" />
              </div>
              <div v-else @click="goToPdf(sample)" class="pdf-div">
                <img :src="pdf_icon" alt="pdf icon" width="30" height="30" />
              </div>
            </div>
            <v-expansion-panel-content>
              <v-expansion-panel-text>
                <div v-if="sampleHasTests(sample)">
                  <div v-for="(item, itemIndex) in comparisonArray" :key="item">
                    <template v-if="sample[item] !== undefined">
                      <!-- Check if the item is an array -->
                      <template v-if="Array.isArray(sample[item])">
                        <div v-for="(test, testIndex) in sample[item]" :key="`${testIndex}-${itemIndex}`"
                          class="well-content" @click="
            openTestPage(sample, item, testIndex, itemIndex)
            ">
                          <div class="sample-test-div">
                            <img :src="test_image" alt="Panel Image" class="mr-2" width="20" height="20" />
                          </div>
                          {{ displayTestType(item) }}
                          <span class="test-temperature" v-if="test.testTemperature && test.testTemperature_UOM
            ">
                            ({{ test.testTemperature }}
                            {{ test.testTemperature_UOM }})
                          </span>
                        </div>
                      </template>
                      <!-- If not an array, display it once -->
                      <div class="well-content" @click="openTestPage(sample, item, itemIndex)" v-else>
                        <div class="sample-test-div">
                          <img :src="test_image" alt="Panel Image" class="mr-2" width="20" height="20" />
                        </div>
                        {{ displayTestType(item) }}
                        <span class="test-temperature" v-if="sample[item].testTemperature &&
            sample[item].testTemperature_UOM
            ">
                          ({{ sample[item].testTemperature }}
                          {{ sample[item].testTemperature_UOM }})
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-else class="loader-div justify-start pl-3">
                  No test found.
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
          <div class="text-center">
          {{ timeoutMessage }}
          <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
        </div>
        </div>
        <div class="no-asset-div" v-else-if="samples.length == 0 && showLoader == false && timeoutMessage == ''
            ">
          <h4>No sample(s) founds.</h4>
        </div>
      </v-col>
    </v-row>
    <ErrorPopup />
  </div>
</template>

<script>
import api from "../../../axiosInterceptor"
import TabularLoaders from "../Loaders/TabularLoaders.vue";
import { mapActions } from "vuex";
import ErrorPopup from '../Popups/ErrorPopup.vue';

export default {
  name: "Sample",
  components: {
    TabularLoaders,
    ErrorPopup,
  },
  data() {
    return {
      samples: [],
      tenantId: localStorage.getItem("tenantId"),
      sample_image: require("../../../assets/Images/Assets/wells.png"),
      test_image: require("../../../assets/Images/Assets/sample.png"),
      pdf_icon: require("../../../assets/Images/pdfIcon.png"),
      pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
      allTests: [],
      loader: true,
      openPanelIndex: null,
      showLoader: true,
      showAllSamples: false,
      pdfLoaders: {},
      snackbarMessage: null,
      expandedPanels: [],
      selectedSampleID: null,
      comparisonArray: [
        "composition",
        "dltests",
        "ccetests",
        "cvdtests",
        "separatortests",
        "viscositytests",
      ],
      expandAllSamples: false,
      timeoutMessage: "",
    };
  },
  computed: {
    displayTestType() {
      return function (testType) {
        switch (testType) {
          case "composition":
            return "Fluid Composition";
          case "dltests":
            return "DL Test";
          case "ccetests":
            return "CCE Test";
          case "cvdtests":
            return "CVD Test";
          case "separatortests":
            return "Separator Test";
          case "viscositytests":
            return "Viscosity Test";
          default:
            return testType;
        }
      };
    },
  },
  methods: {
    ...mapActions("test", ["setActiveTestIndex"]),
    ...mapActions("api", ["showSnackbar"]),
    fetchData(wellName) {
      this.showLoader = true;
      this.timeoutMessage = "";
      const wellString = JSON.stringify(wellName);

      api
        .get(`public/api/v1/samples`, {
          params: {
            well: wellString,
            viewSummary: true,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            if (this.showAllSamples) {
              this.samples = response.data.data.samples;
            } else {
              this.samples = response.data.data.samples.filter(
                (sample) => sample.IsTestAvailable
              );
            }
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    sampleHasTests(sample) {
      return sample.IsTestAvailable;
    },
    expandAll() {
      this.expandedPanels = this.samples.map((sample) => sample.ID);
    },
    collapseAll() {
      this.expandedPanels = [];
    },
    showTests(sample) {
      this.loader = true;
      const sampleId = sample.ID;
      api
        .get(
          `public/api/v1/samples/${sampleId}/tests`
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.loader = false;
            this.allTests = response.data.data.allTests;
          }
        })
        .catch((error) => {
            this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    openTestPage(sample, item, index) {
      // Filter comparisonArray to include only items present in the sample object
      const filteredComparisonArray = this.comparisonArray.filter(
        (comparisonItem) => sample.hasOwnProperty(comparisonItem)
      );

      // Find the index of the clicked item within the filtered comparisonArray
      let cumulativeIndex = 0;
      let itemIndex = -1;
      for (let i = 0; i < filteredComparisonArray.length; i++) {
        if (filteredComparisonArray[i] === item) {
          itemIndex = cumulativeIndex;
          break;
        }
        if (Array.isArray(sample[filteredComparisonArray[i]])) {
          cumulativeIndex += sample[filteredComparisonArray[i]].length;
        } else {
          cumulativeIndex++;
        }
      }

      this.setActiveTestIndex(itemIndex);
      const sampleId = sample.ID;
      const testType = item.endsWith("s") ? item.slice(0, -1) : item;
      let testId;
      if (testType === "composition") {
        testId = sample[item].ID;
      } else {
        testId = sample[item][index].ID;
      }
      localStorage.setItem("sampleId", sampleId);
      localStorage.setItem("testId", testId);
      // const routeParams = { ...this.$route.params };
      // let newRoute = `/assets/${routeParams.asset}/${routeParams.field}/${routeParams.well}/${testType}/${testId}`;
      let routeParams = localStorage.getItem("lastView")
      let newRoute = `${routeParams}/${testType}/${testId}`
      this.$router.push(newRoute);
    },
    goToPdf(sample) {
      this.snackbarMessage = null;
      this.pdfLoaders[sample.ID] = true;
      const sampleId = sample.ID;
      api
        .get(
          `public/api/v1/samples/${sampleId}/download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            this.pdfLoaders[sample.ID] = false;
            window.open(url, "_blank");
          }
        })
        .catch((error) => {
          if (error && error.response) {
            this.pdfLoaders[sample.ID] = false;
          }
        });
    },
  },
  watch: {
    $route(to, from) {
      this.fetchData(to.params.well);
    },
    showAllSamples(value) {
      this.fetchData(this.$route.params.well);
    },
    expandAllSamples() {
      if (this.expandAllSamples === true) {
        this.expandAll();
      } else {
        this.collapseAll();
      }
    },
  },
  mounted() {
    this.fetchData(this.$route.params.well);
  },
};
</script>