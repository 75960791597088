<template>
  <div class="data-management">
    <v-form>
      <ScreenLoader v-if="showLoader == true"></ScreenLoader>
      <v-row
        class="align-baseline"
        no-gutters
        v-else-if="Array.isArray(this.dashboardsArr) && this.dashboardsArr.length != 0"
      >
        <v-col cols="12" class="pa-0 pr-4">
          <v-select
            v-model="dashboard"
            :items="dashboardName"
            item-text="Name"
            @update:modelValue="() => changeDashboard()"
            placeholder="Select saved dashboard"
          >
          </v-select>
        </v-col>
      </v-row>
      <div v-else-if="!Array.isArray(this.dashboardsArr) || this.dashboardsArr.length == 0" class="text-center pt-5">
        <h4>
        No dashboard available
      </h4>
      </div>
      <div v-else-if="timeoutMessage != ''" class="timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon
            flat
            @click="getSavedDashboards()"
            color="blue-darken-3"
            icon="mdi-reload"
            size="large"
          ></v-icon>
        </div>
      </div>
      <ChartLoaders class="pr-2" v-if="showChartsLoader == true"></ChartLoaders>
      <div class="pb-3 pr-4"
        v-else-if="Array.isArray(chartTypes) && chartTypes.length != 0">
        <h6 class="main-heading mb-2">Create Dashboard</h6>
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 pb-5">
            <v-text-field
              v-model="name"
              required
              placeholder="Enter dashboard name"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-select
              clearable
              v-model="chart_one"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_one)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <v-select
              clearable
              v-model="chart_two"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_two)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-select
              clearable
              v-model="chart_three"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_three)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <v-select
              clearable
              v-model="chart_four"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_four)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-select
              clearable
              v-model="chart_five"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_five)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <v-select
              clearable
              v-model="chart_six"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_six)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="pa-0">
            <v-btn
              block
              flat
              @click="createDashboard"
              :loading="loading"
              :disabled="isSaveDisabled"
              class="save-btn text-none"
              >Save Dashboard</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div v-else-if="!Array.isArray(chartTypes) || chartTypes.length == 0" class="no-test-div text-center">
        <h4>
          No charts found. <br />
          please create charts first from
          <router-link to="/Fluidsdata-studio" exact>
            <span>Fluidsdata studio page</span>
          </router-link>
        </h4>
      </div>
      <div v-else-if="timeoutMessageCharts != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessageCharts }}
          <v-icon
            flat
            @click="getSavedCharts()"
            color="blue-darken-3"
            icon="mdi-reload"
            size="large"
          ></v-icon>
        </div>
      </div>
      <!-- <div
        v-else-if="
        Array.isArray(chartTypes) &&
          showLoader == false &&
          showChartsLoader == false &&
          timeoutMessage == '' &&
          timeoutMessageCharts == '' &&
          chartTypes.length == 0
        "
        class="no-asset-div text-center"
      >
        <h4>
          No charts found. <br />
          please create charts first from
          <router-link to="/fluidsdata-studio" exact>
            <span>fluidsdata studio page</span>
          </router-link>
        </h4>
      </div> -->
    </v-form>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import api from "../../../axiosInterceptor";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import ChartLoaders from "../Loaders/ChartLoaders.vue";
export default {
  components: { ScreenLoader, ChartLoaders },
  name: "ChartInputs",
  data() {
    return {
      dashboardsArr: [],
      selectedChartIds: [],
      dashboardName: [],
      name: "",
      chart_one: null,
      chart_two: null,
      chart_three: null,
      chart_four: null,
      chart_five: null,
      chart_six: null,
      chartTypes: [],
      chartTypesName: [],
      loading: false,
      showAlert: false,
      errorMessage: "",
      dashboard: null,
      showLoader: true,
      showChartsLoader: false,
      timeoutMessage: "",
      timeoutMessageCharts: "",
    };
  },
  computed: {
    isSaveDisabled() {
      return (
        !this.name ||
        !(
          this.chart_one ||
          this.chart_two ||
          this.chart_three ||
          this.chart_four ||
          this.chart_five ||
          this.chart_six
        )
      );
    },
  },
  methods: {
    createDashboard() {
      const tenantId = localStorage.getItem("tenantId");

      // Push each selected chart ID into the array
      for (let i = 1; i <= 6; i++) {
        if (this[`selectedChartId${i}`]) {
          this.selectedChartIds.push(this[`selectedChartId${i}`]);
        }
      }

      const requestBody = {
        Data: {
          dashboard: {
            name: this.name,
            chartsID: this.selectedChartIds,
            AccessLevel: "private",
          },
        },
      };

      this.loading = true;

      axios
        .post(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards",
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          const dashboard_name = this.name.replace(/\s/g, "-");
          this.$router.push({
            name: "Board",
            params: { name: dashboard_name },
          });
        })
        .catch((error) => {
          this.showAlert = true;
          this.errorMessage = error.response.data.error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSavedCharts() {
      this.showChartsLoader = true;
      this.timeoutMessageCharts = "";
      api
        .get("public/api/v1/charts")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.charts;
            this.chartTypesName = this.chartTypes.map((chart) => chart.Name,
            );
          }
        })
        .catch((error) => {
          this.timeoutMessageCharts = error;
        })
        .finally(() => {
          this.showChartsLoader = false;
        });
    },

    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards.map(
              (dashboard) => dashboard.name
            );
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          1;
          this.showLoader = false;
        });
    },

    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },
    

    chartTypeChange(selectedChartName) {
      // Reset selectedChartIds array
      this.selectedChartIds = [];

      // Add ID of selected chart for chart_one
      if (this.chart_one) {
        const chartOne = this.chartTypes.find(
          (chart) => chart.Name === this.chart_one
        );
        if (chartOne) {
          this.updateSelectedChartIds(chartOne.ID);
        }
      }

      // Remove selected charts from chartTypes for chart_two options
      let availableChartsForChartTwo = this.chartTypes
        .filter((chart) => {
          return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
        })
        .map((chart) => {
          return { ID: chart.ID, Name: chart.Name };
        });

      // Update chartTypesName for chart_two dropdown
      this.chartTypesName = availableChartsForChartTwo.map(
        (chart) => chart.Name
      );

      // Add ID of selected chart for chart_two
      if (this.chart_two) {
        const chartTwo = availableChartsForChartTwo.find(
          (chart) => chart.Name === this.chart_two
        );
        if (chartTwo) {
          this.updateSelectedChartIds(chartTwo.ID);
        }
      }

      // Remove selected charts from chartTypes for chart_three options
      let availableChartsForChartThree = availableChartsForChartTwo
        .filter((chart) => {
          return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
        })
        .map((chart) => {
          return { ID: chart.ID, Name: chart.Name };
        });

      // Update chartTypesName for chart_three dropdown
      this.chartTypesName = availableChartsForChartThree.map(
        (chart) => chart.Name
      );

      // Add ID of selected chart for chart_three
      if (this.chart_three) {
        const chartThree = availableChartsForChartThree.find(
          (chart) => chart.Name === this.chart_three
        );
        if (chartThree) {
          this.updateSelectedChartIds(chartThree.ID);
        }
      }

      let availableChartsForChartFour = availableChartsForChartThree
        .filter((chart) => {
          return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
        })
        .map((chart) => {
          return { ID: chart.ID, Name: chart.Name };
        });

      // Update chartTypesName for chart_three dropdown
      this.chartTypesName = availableChartsForChartFour.map(
        (chart) => chart.Name
      );

      // Add ID of selected chart for chart_three
      if (this.chart_four) {
        const chartFour = availableChartsForChartFour.find(
          (chart) => chart.Name === this.chart_four
        );
        if (chartFour) {
          this.updateSelectedChartIds(chartFour.ID);
        }
      }

      let availableChartsForChartFive = availableChartsForChartFour
        .filter((chart) => {
          return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
        })
        .map((chart) => {
          return { ID: chart.ID, Name: chart.Name };
        });

      // Update chartTypesName for chart_three dropdown
      this.chartTypesName = availableChartsForChartFive.map(
        (chart) => chart.Name
      );

      // Add ID of selected chart for chart_three
      if (this.chart_five) {
        const chartFive = availableChartsForChartFive.find(
          (chart) => chart.Name === this.chart_five
        );
        if (chartFive) {
          this.updateSelectedChartIds(chartFive.ID);
        }
      }

      let availableChartsForChartSix = availableChartsForChartFive
        .filter((chart) => {
          return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
        })
        .map((chart) => {
          return { ID: chart.ID, Name: chart.Name };
        });

      // Update chartTypesName for chart_three dropdown
      this.chartTypesName = availableChartsForChartSix.map(
        (chart) => chart.Name
      );

      // Add ID of selected chart for chart_three
      if (this.chart_six) {
        const chartSix = availableChartsForChartSix.find(
          (chart) => chart.Name === this.chart_six
        );
        if (chartSix) {
          this.updateSelectedChartIds(chartSix.ID);
        }
      }
    },

    changeDashboard() {
      const selectedDashboard = this.dashboardsArr.find(
        (dashboard) => dashboard.name === this.dashboard
      );

      if (selectedDashboard) {
        const dashboardName = selectedDashboard.name.replace(/\s/g, "-");
        this.$router.push({
          name: "Board",
          params: { name: dashboardName },
        });
      }
    },
  },
  mounted() {
    this.getSavedDashboards();
    this.getSavedCharts();
    const lastVisitedDashboard = localStorage.getItem("lastVisitedDashboard");
    if (lastVisitedDashboard) {
      this.$router.push(lastVisitedDashboard);
    }
  },
};
</script>