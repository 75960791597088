<template>
  <div>
    <div v-if="loading">
      <ScreenLoader />
    </div>
    <div v-else>
      <h3 class="mb-1">{{ chartName }}</h3>
      <div class="main-chart">
        <div :ref="chartId"></div>
        <v-btn @click="openModal" v-if="chartType" flat size="small" class="chart-preview-btn2 text-none">Expand</v-btn>
        <v-dialog v-model="isModalOpen" width="100vw">
          <v-card>
            <div class="modal-header header-div">
              <v-spacer></v-spacer>
              <v-btn @click="closeModal" flat icon size="x-small">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <div :ref="modalChartId"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ScreenLoader from "../Loaders/ScreenLoader.vue";

export default {
  name: "DynamicChart",
  components: {
    ScreenLoader
  },
  props: {
    loading: { type: Boolean, default: true },
    chartName: { type: String, default: "" },
    chartType: { type: String, required: true },
    xAxisData: { type: Array, default: () => [] },
    yAxisData: { type: Array, default: () => [] },
    zAxisData: { type: Array, default: () => [] },
    xColorGroupData: { type: Array, default: [] },
    yColorGroupData: { type: Array, default: [] },
    zColorGroupData: { type: Array, default: [] },
    colorGroupKey: { type: String, default: "" },
    markerSizes: Array,
    xAxisDisplayName: { type: String, default: "" },
    yAxisDisplayNames: { type: Array, default: () => [] },
    xBins: { type: Number, default: 10 },
    yBins: { type: Number, default: 10 }
  },
  data() {
    return {
      chartId: "dynamicChart",
      modalChartId: "modalChart",
      isModalOpen: false,
      hasValidXColorGroupData: false,
      hasValidYColorGroupData: false,
    };
  },
  watch: {
    xAxisData: "plotChart",
    yAxisData: "plotChart",
    zAxisData: "plotChart",
    xColorGroupData: "plotChart",
    yColorGroupData: "plotChart",
    zColorGroupData: "plotChart",
    chartType: "plotChart",
    xBins: "plotChart",
    yBins: "plotChart",
  },
  methods: {
    plotChart() {
      if (!this.$refs[this.chartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();
      Plotly.newPlot(this.$refs[this.chartId], traces, layout, config)
    },
    plotModalChart() {
      if (!this.$refs[this.modalChartId]) return;

      const traces = this.generateTraces();
      const layout = this.generateLayout();
      const config = this.generateConfig();

      this.$nextTick(() => {
        Plotly.newPlot(this.$refs[this.modalChartId], traces, layout, config)
      });
    },

    generateTraces() {
      const traceType = this.getTraceType();
      const traceMode = this.getTraceMode();
      const scatterMarkerSizes = 8;

      this.hasValidXColorGroupData = Object.values(this.xColorGroupData).some(group => Array.isArray(group) && group.length > 0);
      this.hasValidYColorGroupData = Object.values(this.yColorGroupData).some(group => Array.isArray(group) && group.length > 0);

      if (this.chartType === "Histogram") {
        // Handled Histogram separately
        if (this.hasValidXColorGroupData) {
          return Object.keys(this.xColorGroupData).map((key) => {
            const xData = this.xColorGroupData[key];
            const trace = {
            x: xData,
            type: traceType,
            autobinx: false,
            mode: traceMode,
            name: key,
            xbins: { size: this.calculateBinSize(xData, this.xBins) }
          };
          return trace;
          })
        }
        else {
          return [{
            x: this.xAxisData,
            type: traceType,
            name: this.chartName,
            autobinx: false,
            xbins: { size: this.calculateBinSize(this.xAxisData, this.xBins) }
          }];
        }
      } else if (this.hasValidXColorGroupData && this.hasValidYColorGroupData) {
        // Generate traces for each pair of xColorGroupData and yColorGroupData
        return Object.keys(this.xColorGroupData).map((key) => {
          const xData = this.xColorGroupData[key];
          let yData = this.yColorGroupData[key];
          const zData = this.zColorGroupData[key];
          const markerSize = (this.chartType === "Scatter Plot") ? scatterMarkerSizes : this.markerSizes;

          // Ensure xData and yData are arrays and have the same length
          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]); // Assuming each item is an array with one value

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize,
            },
            name: key  // Use the key as the legend label
          };

          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }

          return trace;
        }).filter(trace => trace !== null);
      }
      else {
        return this.yAxisData.map((yData, index) => {
          const xData = this.xAxisData;
          const zData = this.zAxisData;
          const markerSize = (this.chartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            marker: {
              size: markerSize
            },
            name: this.yAxisDisplayNames[index] || `Series ${index + 1}`
          };
          if (this.chartType === "Bubble" || this.chartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          if (["2D Histogram", "Histogram", "Bubble", "Heatmap"].includes(this.chartType)) {
            trace.autobinx = false;
            trace.autobiny = false;
            trace.xbins = { size: this.calculateBinSize(xData, this.xBins) };
            trace.ybins = { size: this.calculateBinSize(yData, this.yBins) };
          }
          return trace;
        });
      }
    },
    generateLayout() {
      return {
        title: this.getLayoutTitle(),
        xaxis: { title: this.xAxisDisplayName },
        yaxis: { title: this.yAxisDisplayNames.join(', ') },
        legend: {
          title: {
            text: this.hasValidXColorGroupData && this.hasValidYColorGroupData ? this.colorGroupKey : ''
          }
        },
      };
    },
    generateConfig() {
      return {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d']
      };
    },
    getTraceType() {
      switch (this.chartType) {
        case "Line Chart": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },
    getTraceMode() {
      if (this.chartType === "Bubble") return "markers";
      if (this.chartType === "Scatter Plot") return "markers";
      return undefined;
    },
    getLayoutTitle() {
      const titles = {
        "Line Chart": "Line Chart",
        "Bar Chart": "Bar Chart",
        "Histogram": "Histogram Chart",
        "Bubble": "Bubble Chart",
        "3D Scatter": "3D Scatter Chart",
        "Scatter Plot": "Scatter Chart",
        "2D Histogram": "2D Histogram Chart",
        "Heatmap": "Heatmap Chart",
        "Treemap": "Treemap Chart"
      };
      return titles[this.chartType] || "";
    },
    calculateBinSize(data, bins) {
      const numericData = data.filter(value => typeof value === 'number' && !isNaN(value));
      if (numericData.length === 0) return 1;
      const minValue = Math.min(...numericData);
      const maxValue = Math.max(...numericData);
      return (maxValue - minValue) / bins;
    },
    openModal() {
      this.isModalOpen = true;
      this.$nextTick(this.plotModalChart);
    },
    closeModal() {
      this.isModalOpen = false;
    }
  },
  mounted() {
    this.$nextTick(this.plotChart);
  }
};
</script>