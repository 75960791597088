export default {
  data() {
    return {
      drawerOpen: true,
      rightDrawerOpen: false,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    rightSubContentStyle() {
      let width = "100vw";
      let left = "0px";
      let right = "0px";

      if (this.screenWidth >= 768) {
        if (this.drawerOpen) {
          left = "330px";
          width = "calc(100vw - 330px)";
        }
        // if (this.rightDrawerOpen && this.$route.name === "FluidsDataStudio") {
         if (this.rightDrawerOpen) {
          right = "330px";
          width = this.drawerOpen ? "calc(100vw - 660px)" : "calc(100vw - 330px)";
        }
      }

      return {
        width: width,
        left: left,
        right: right,
      };
    },
    rightContentStyle() {
      if (this.screenWidth < 768) {
        return {
          paddingLeft: "35px",
          paddingRight: "0",
        };
      } else {
        // if (this.rightDrawerOpen && this.$route.name === "FluidsDataStudio") {
        // if(this.rightDrawerOpen) {
        //   return {
        //     paddingRight: this.rightDrawerOpen ? "18px" : "0px",
        //   }
        // }
        return {
          paddingLeft: this.drawerOpen ? "18px" : "35px",
        }
      };
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleDrawerStateChange(isOpen) {
      if (this.screenWidth >= 768) {
        this.drawerOpen = isOpen;
      }
    },
    handleRightDrawerStateChange(isOpen) {
      if (this.screenWidth >= 768) {
        this.rightDrawerOpen = isOpen;
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};  