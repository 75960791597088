<template>
  <ChartInputs />
</template>
      
  <script>
import ChartInputs from "@/components/layout/Dashboard/ChartInputs.vue";
export default {
  components: { ChartInputs },
  name: "Main",
};
</script>