<template>
  <div class="data-management">
    <v-row no-gutters>
      <GridLoaders v-if="showLoader" />
      <v-col v-else-if="fields.length > 0" v-for="(field, index) in fields" :key="index" cols="12" md="6" lg="3"
        @click="navigateToWells(field)">
        <v-sheet class="mb-4 px-2">
          <v-card elevation="3" link>
            <v-card-title class="assets_name">Field : {{ field.name }}
            </v-card-title>
            <v-card-text class="assets-data">
              <div>Asset : {{ assetName }}</div>
              <div>Wells : {{ field.counts.wells }}</div>
              <div>Samples : {{ field.counts.samples }}</div>
              <div>DL Tests : {{ field.counts.dltests }}</div>
              <div>CCE Tests : {{ field.counts.ccetests }}</div>
              <div>Compositions : {{ field.counts.compositions }}</div>
              <div>CVD Tests : {{ field.counts.cvdtests }}</div>
              <div>Separator Tests : {{ field.counts.separatortests }}</div>
              <div>Viscosity Tests : {{ field.counts.viscositytests }}</div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
        </div>
      </div>
      <div class="no-asset-div" v-else>
        <h4>No field(s) found.</h4>
      </div>
    </v-row>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import GridLoaders from "../Loaders/GridLoaders.vue";

export default {
  name: "SampleFields",
  components: {
    GridLoaders,
  },
  data: () => ({
    fields: [],
    showLoader: false,
    assetName: "",
    timeoutMessage: "",
  }),
  methods: {
    fetchData() {
      this.showLoader = true;
      this.timeoutMessage = "";
      this.assetName = this.$route.params.asset.replace(/-/g, " ");
      const sanitizedAssetName = this.assetName.replace(/\\/g, "/");
      const assetString = JSON.stringify(sanitizedAssetName);

      api
        .get(`public/api/v1/locations/fields?asset=${encodeURIComponent(assetString)}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.fields = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToWells(field) {
      const fieldName = field.name.replace(/\s/g, "-");
      const sanitizedFieldName = fieldName.replace(/\//g, "\\");
      this.$router.push({
        name: "Wells",
        params: { field: sanitizedFieldName },
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>