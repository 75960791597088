<template>
  <div class="data-management">
    <v-row no-gutters class="mb-2 pr-4">
      <!-- <v-col cols="12" md="3">
      <WellsLeft @field-selected="fetchWells"/>
    </v-col> -->
      <v-col cols="12">
        <TabularLoaders v-if="showLoader" />
        <v-expansion-panels class="mb-4 pl-3" variant="accordion" v-else-if="wells.length > 0">
          <v-expansion-panel v-for="(well, Index) in wells" :key="Index" @click="navigateToSamples(well)">
            <v-expansion-panel-title expand-icon="mdi-chevron-right" collapse-icon="mdi-chevron-down">
              <div class="well-data">
                <div class="well-content">
                  <img :src="well_image" alt="Panel Image" class="mr-2" width="44" height="44" />
                  <div>
                    <div class="title">Well: {{ well.name }}</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-content>
              <v-expansion-panel-text v-for="(section, sIndex) in well.sections" :key="sIndex">
                <div class="pl-9 well-data">
                  <div class="well-content">
                    <img :src="section.image" alt="Section Image" class="mr-2" width="24" height="24" />
                    <div>
                      <div class="title mb-1">{{ section.title }}</div>
                      <div class="desc">{{ section.description }}</div>
                    </div>
                  </div>
                  <span class="sample-text">
                    {{ sIndex + 1 }} {{ sIndex + 1 > 1 ? "Tests" : "Test" }}
                  </span>
                </div>
                <v-divider v-if="sIndex !== well.sections.length - 1"></v-divider>
              </v-expansion-panel-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
          <div class="text-center">
            {{ timeoutMessage }}
            <v-icon flat @click="fetchData()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
          </div>
        </div>
        <div class="no-asset-div" v-else-if="wells.length == 0 && showLoader == false && timeoutMessage == ''
          ">
          <h4>No well(s) founds.</h4>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor"
// import WellsLeft from "./WellsLeft.vue";
import TabularLoaders from "../Loaders/TabularLoaders.vue";
export default {
  name: "SampleWells",
  components: {
    // WellsLeft,
    TabularLoaders,
  },
  data() {
    return {
      wells: [],
      tenantId: localStorage.getItem("tenantId"),
      well_image: require("../../../assets/Images/Assets/wells.png"),
      showLoader: false,
      timeoutMessage: "",
    };
  },
  methods: {
    fetchData() {
      this.showLoader = true;
      this.timeoutMessage = "";
      const fieldName = this.$route.params.field.replace(/-/g, " ");
      const sanitizedFieldName = fieldName.replace(/\\/g, "/");
      const fieldString = JSON.stringify(sanitizedFieldName);

      api
        .get(`public/api/v1/locations/wells?field=${encodeURIComponent(fieldString)}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.wells = response.data.data.locations.sort((a, b) => {
              return (
                parseInt(a.name.split("-")[1]) - parseInt(b.name.split("-")[1])
              );
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToSamples(well) {
      const wellName = well.name.replace(/\s/g, "-");
      this.$router.push({
        name: "Samples",
        params: { well: wellName },
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>