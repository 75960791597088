<template>
  <div>
    <div class="top-wrap">
      <h2> Composition Analysis </h2>
      <div class="top-content" v-if="receivedData">
        <div v-if="receivedData.testID != ''">
          <div class="top-content-data">
            <v-avatar size="80" rounded="0">
              <v-img src="../../../assets/Images/Assets/sample.png" alt="test image"></v-img>
            </v-avatar>
            <div>
              <p>Test ID: {{ receivedData.testID }} </p>
              <p v-if="receivedData.sampleID">Sample ID: {{ receivedData.sampleID }} </p>
              <p v-if="receivedData.saturationPressure_UOM && receivedData.saturationPressure">Saturation Pressure: {{ receivedData.saturationPressure }}
                <span> ({{
        receivedData.saturationPressure_UOM }})
                </span>
              </p>
              <p v-if="receivedData.testTemperature && receivedData.testTemperature_UOM">Test Temperature: {{ receivedData.testTemperature }}
                <span> ({{
        receivedData.testTemperature_UOM }})
                </span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="pdfLoader === true" class="pdf-div">
          <img :src="pdf_loader_image" alt="loader Image" width="30" height="30" />
        </div>
        <div v-else-if="receivedData.testID !== ''" @click="goToPdf()" class="pdf-div">
          <img :src="pdf_icon" alt="pdf icon" width="30" height="30" />
        </div>


        <div v-if="receivedData.testID == ''">
          No data available.
        </div>
      </div>


      <div class="top-content" v-else>
        <test-loaders />
      </div>
    </div>
    <div ref="scrollable" class="tabs-data" tabindex="0" @keydown="handleKeyDown">
      <v-tabs v-model="tab">
        <v-tab value="one">Composition Analysis</v-tab>
        <v-tab value="two">Composition Properties</v-tab>
      </v-tabs>

      <v-card-text class="pa-0">
        <v-window v-model="tab">
          <v-window-item value="one">
            <CompositionAnalysisTab @data-updated="handleDataUpdate" />
          </v-window-item>

          <v-window-item value="two">
            <CompositionPropertiesTab @data-updated="handleDataUpdate" />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import CompositionAnalysisTab from "./Tables/CompositionAnalysisTab.vue";
import CompositionPropertiesTab from "../CompositionAnalysis/Tables/CompositionPropertiesTab.vue";
import TestLoaders from '../Loaders/TestLoaders.vue';
import axios from "axios";
export default {
  components: { CompositionAnalysisTab, CompositionPropertiesTab, TestLoaders },
  name: "CompositionTabs",
  data: () => ({
    tab: null,
    receivedData: null,
    assetName: "",
    fieldName: "",
    wellName: "",
    pdf_icon: require("../../../assets/Images/pdfIcon.png"),
    pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
    pdfLoader: false,
    snackbarMessage: null,
  }),
  methods: {
    handleDataUpdate(data) {
      this.receivedData = data;
    },
    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.snackbarMessage = "PDF data is not available."
            this.pdfLoader = false;
          }
        });
    },
    handleKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowDown') {
        // Handle scrolling down
        this.$refs.scrollable.scrollTop += 50; // You can adjust the scrolling speed as needed
      } else if (key === 'ArrowLeft') {
        // Handle switching to the previous tab
        const currentIndex = this.tab ? ['one', 'two'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 0 ? 'two' : 'one';
      } else if (key === 'ArrowRight') {
        // Handle switching to the next tab
        const currentIndex = this.tab ? ['one', 'two'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 1 ? 'one' : 'two';
      }
    },
  },
  mounted() {
    this.tab = 'one';
    this.assetName = this.$route.params.asset.replace(/-/g, ' ');
    this.fieldName = this.$route.params.field.replace(/-/g, ' ');
    this.wellName = this.$route.params.well;
    this.$refs.scrollable.focus();
  },
};
</script>