<template>
  <div class="data-management">
    <v-row no-gutters>
      <GridLoaders v-if="showLoader == true" />
      <v-col v-else-if="assets.length > 0" v-for="(asset, index) in filteredAssets" :key="index" cols="12" md="6" lg="3"
        @click="navigateToFields(asset)">
        <v-sheet class="mb-4 px-2">
          <v-card elevation="3" link>
            <v-card-title class="assets_name">
              Asset : {{ asset.name }}
            </v-card-title>
            <v-card-text class="assets-data">
              <div>Fields : {{ asset.counts.fields }}</div>
              <div>Wells : {{ asset.counts.wells }}</div>
              <div>Samples : {{ asset.counts.samples }}</div>
              <div>DL Tests : {{ asset.counts.dltests }}</div>
              <div>CCE Tests : {{ asset.counts.ccetests }}</div>
              <div>Compositions : {{ asset.counts.compositions }}</div>
              <div>CVD Tests : {{ asset.counts.cvdtests }}</div>
              <div>Separator Tests : {{ asset.counts.separatortests }}</div>
              <div>Viscosity Tests : {{ asset.counts.viscositytests }}</div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
      <div v-else-if="timeoutMessage != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon flat @click="getAllAssets()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
        </div>
      </div>
      <div class="no-asset-div" v-else-if="assets.length == 0 && showLoader == false && timeoutMessage == ''
        ">
        <h4>No asset(s) found.</h4>
      </div>
    </v-row>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import GridLoaders from "../Loaders/GridLoaders.vue";
import { mapGetters } from "vuex";

export default {
  name: "SampleAssets",
  components: {
    GridLoaders,
  },
  data: () => ({
    assets: [],
    showLoader: false,
    timeoutMessage: "",
  }),
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    filteredAssets() {
      return this.assets.filter((asset) => asset.name);
    },
  },
  methods: {
    getAllAssets() {
      this.showLoader = true;
      this.assets = [];
      this.timeoutMessage = "";

      api
        .get("public/api/v1/locations/assets")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.assets = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToFields(asset) {
      const assetName = asset.name.replace(/\s/g, "-");
      const sanitizedAssetName = assetName.replace(/\//g, "\\");
      this.$router.push({
        name: "Fields",
        params: { asset: sanitizedAssetName },
      });
    },
  },
  watch: {
    getSelectedTenant: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAllAssets();
        }
      },
      immediate: true,
    },
  },
};
</script>
