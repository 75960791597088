<template>
  <v-breadcrumbs :items="breadcrumbItems" divider="/">
    <template v-slot:prepend>
      <v-icon size="small" icon="mdi-home"></v-icon>
    </template>
    <template v-slot:item="{ item, index }">
      <div class="text-capitalize" v-if="index === breadcrumbItems.length - 1 && !showTestsDropdown && item.title === 'Tests'">
        {{ item.title }}
        <v-icon size="small" color="grey" @click="toggleTestsDropdown">mdi-chevron-down</v-icon>
      </div>
      <div class="main-dropdown" v-else-if="showTestsDropdown && item.title === 'Tests'" ref="dropdownMenu">
        {{ item.title }}
        <v-icon size="small" color="grey" @click="toggleTestsDropdown">mdi-chevron-up</v-icon>
        <div class="dropdown-menu" v-if="allTests.length > 0">
          <div class="test-name-div" v-for="(test, index) in allTests" :key="index">
            <div class="breadcrumb-test-name" @click="openTestPage(test, index)"
              :class="{ 'active': activeTestIndex === index }">
              {{ displayTestType(test.testType) }}
              <span v-if="test.testTemperature && test.testTemperature_UOM">
                ({{ test.testTemperature }}
                {{ test.testTemperature_UOM }})
              </span>
            </div>
          </div>
        </div>
      </div>
      <router-link class="text-capitalize" @click.native="handleBreadcrumb(item.title)" v-else :to="{ path: item.href }">
        {{ item.title }}
        <v-icon size="small" color="grey">mdi-chevron-down</v-icon>
      </router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "Links",
  data: () => ({
    items: [],
    currentURLSegments: [],
    showTestsDropdown: false,
    tenantId: localStorage.getItem("tenantId"),
    allTests: [],
  }),
  computed: {
    ...mapGetters("test", ['activeTestIndex']),
    breadcrumbItems() {
      const itemsCopy = this.items.slice();
      const assetParam = this.$route.params.asset;
      const fieldParam = this.$route.params.field;
      const wellParam = this.$route.params.well;
      const currentRoute = this.$route.path;

      if (
        currentRoute.includes("/composition") ||
        currentRoute.includes("/dltest") ||
        currentRoute.includes("/ccetest") ||
        currentRoute.includes("/cvdtest") ||
        currentRoute.includes("/separatortest") ||
        currentRoute.includes("/viscositytest")
      ) {
        itemsCopy.push(
          { title: assetParam ? assetParam : "Assets", href: "/assets" },
          { title: fieldParam, href: `/assets/${assetParam}` },
          { title: wellParam, href: `/assets/${assetParam}/${fieldParam}` },
          { title: "Samples", href: `/assets/${assetParam}/${fieldParam}/${wellParam}` },
          { title: "Tests", href: `/assets/${assetParam}/${fieldParam}/${wellParam}` }
        );
      } else if (wellParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam : "Assets", href: "/assets" },
          { title: fieldParam, href: `/assets/${assetParam}` },
          { title: wellParam, href: `/assets/${assetParam}/${fieldParam}` },
          { title: "Samples", href: `/assets/${assetParam}/${fieldParam}/${wellParam}` }
        );
      } else if (fieldParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam : "Assets", href: "/assets" },
          { title: fieldParam, href: `/assets/${assetParam}` },
          { title: "Wells", href: `/assets/${assetParam}/${fieldParam}` }
        );
      } else if (assetParam) {
        itemsCopy.push(
          { title: assetParam ? assetParam : "Assets", href: "/assets" },
          { title: "Fields", href: `/assets/${assetParam}` }
        );
      } else {
        let currentPath = "/";

        itemsCopy.push(
          ...this.currentURLSegments.map((segment) => {
            currentPath += `${segment}/`;
            let title = segment;
            return {
              title,
              href: currentPath,
              icon: "mdi-chevron-down",
            };
          })
        );
      }
      return itemsCopy;
    },
    displayTestType() {
      return function(testType) {
        switch (testType) {
          case 'composition':
            return 'Fluid Composition';
          case 'dltest':
            return 'DL Test';
            case 'ccetest':
            return 'CCE Test';
            case 'cvdtest':
            return 'CVD Test';
          case 'separatortest':
            return 'Separator Test';
            case 'viscositytest':
            return 'Viscosity Test';
          default:
            return testType;
        }
      }
    },
  },
  methods: {
    ...mapActions("test", ['setActiveTestIndex']),
    updateBreadcrumbs() {
      const currentURL = window.location.pathname;
      this.currentURLSegments = currentURL
        .split("/")
        .filter((segment) => segment !== "");
      let updatedPath = `/${this.currentURLSegments.join("/")}`;
      updatedPath = updatedPath.replace(/\/$/, "");
      window.history.replaceState({}, "", updatedPath);
    },
    toggleTestsDropdown(event) {
      event.stopPropagation();
      this.showTestsDropdown = !this.showTestsDropdown;
    },
    showTests() {
      this.allTests = [];
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/tests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": this.tenantId,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.allTests = response.data.data.allTests
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },
    openTestPage(test, index) {
      this.setActiveTestIndex(index);
      this.showTestsDropdown = false;
      const sampleId = localStorage.getItem("sampleId");
      const testId = test.ID;
      localStorage.setItem("sampleId", sampleId);
      localStorage.setItem("testId", testId);
      const testType = test.testType;
      
      const routeParams = { ...this.$route.params };
      let newRoute = `/assets/${routeParams.asset}/${routeParams.field}/${routeParams.well}/${testType}/${testId}`;
      this.$router.push(newRoute);
    },
    closeDropdownMenuOnClickOutside(event) {
      if (this.showTestsDropdown) {
        if (this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
          this.showTestsDropdown = false;
        }
      }
    },
    handleBreadcrumb(title) {
      if(title === "Visualization-&-Dashboards") {
        localStorage.removeItem("lastVisitedDashboard")
      }
    }
  },

  watch: {
    $route(to, from) {
      this.updateBreadcrumbs();
    },
    breadcrumbItems: {
      handler(newItems, oldItems) {
        const containsTests = newItems.some(item => item.title === 'Tests');
        if (containsTests) {
          this.showTests();
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    document.body.addEventListener("click", this.closeDropdownMenuOnClickOutside);
    this.updateBreadcrumbs();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.closeDropdownMenuOnClickOutside);
  },
};
</script>