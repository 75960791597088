<template>
  <div class="left-menu-wrapper">
    <v-icon
      class="toggle-icon"
      :icon="drawer ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'"
      :style="toggleIconPosition"
      @click="toggleDrawer"
    ></v-icon>
    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      :mini-variant="miniVariant"
      @update:mini-variant="updateMiniVariant"
    >
      <div class="img-wrap">
        <v-img aspect-ratio="16/9" :src="logo.logo" alt="logo"></v-img>
      </div>

      <!-- <v-list v-model:opened="open"> -->
      <v-list>
        <template v-for="(item, index) in navigationItems" :key="index">
          <v-list-item
            v-if="!item.children"
            :class="{ 'active-item': $route.path === item.route }"
          >
            <router-link :to="item.route">
              <v-list-item-content class="menu-wrap">
                <img :src="item.image" alt="Menu Image" />
                <v-list-item-title
                  :class="{ 'active-text': $route.path === item.route }"
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-content>
            </router-link>
          </v-list-item>

          <v-list-group v-else :value="item.title">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :prepend-icon="item.icon">
                <v-list-item-content class="menu-wrap">
                  <img :src="item.image" />
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(childItem, childIndex) in item.children"
              :key="childIndex"
              :class="{ 'active-item': $route.path === childItem.route }"
            >
              <router-link v-if="childItem.route" :to="childItem.route">
                <v-list-item-content class="menu-wrap pl-3">
                  <img :src="childItem.image" />
                  <v-list-item-title :class="{ 'active-text': $route.path === childItem.route }">{{ childItem.title }}</v-list-item-title>
                </v-list-item-content>
              </router-link>
              <v-list-item-content v-else class="menu-wrap pl-3">
                <img :src="childItem.image" />
                <v-list-item-title :class="{ 'active-text': $route.path === childItem.route }">{{ childItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>

      <!-- <div class="img-wrap">
        <v-img aspect-ratio="16/9" :src="logo.companyName" alt="company logo"></v-img>
      </div> -->

      <div class="tenant-img-wrap">
        <div class="img">
          <v-img :src="logo.tenantName" alt="tenant logo"></v-img>
        </div>
        <span>{{ getSelectedTenant ? getSelectedTenant.name : selectedTenant }}</span>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  data: () => ({
    drawer: true,
    miniVariant: false,
    screenWidth: 0,
    selectedTenant: localStorage.getItem("tenantName"),
    logo: {
      // companyName: require("../../../assets/Images/LeftMenu/company name.png"),
      logo: require("../../../assets/Images/LeftMenu/logo.png"),
      tenantName: require("../../../assets/Images/LeftMenu/tenant-name.png")
    },
    navigationItems: [
      {
        title: "data management",
        route: "/assets",
        image: require("../../../assets/Images/LeftMenu/fluid analysis.png"),
      },
      {
        title: "data QA-QC",
        route: "",
        image: require("../../../assets/Images/LeftMenu/data QA-QC.png"),
      },
      {
        title: "fluidsdata studio",
        route: "/Fluidsdata-studio",
        image: require("../../../assets/Images/LeftMenu/dashboard.png"),
      },
      {
        title: "visualization & dashboards",
        route: "/Visualization-&-Dashboards",
        image: require("../../../assets/Images/LeftMenu/dashboard.png"),
      },
      {
        title: "exploratory data analysis (EDA)",
        image: require("../../../assets/Images/LeftMenu/exploratory data analysis (EDA).png"),
        children: [
          {
            title: "Data Inventory Analysis",
            image: require("../../../assets/Images/LeftMenu/exploratory data analysis (EDA).png"),
            route: "/data-inventory-analysis"
          },
          {
            title: "correlation analysis",
            route: "/PVT-Properties",
            image: require("../../../assets/Images/LeftMenu/dashboard.png"),
          },
          {
            title: "fluidsdata insights",
            route: "/Fluidsdata-insights",
            image: require("../../../assets/Images/LeftMenu/dashboard.png"),
          },
        ],
      },
      {
        title: "Property Prediction",
        image: require("../../../assets/Images/LeftMenu/AI-ML Modeling.png"),
        children: [
          {
            title: "AI / ML Modeling",
            image: require("../../../assets/Images/LeftMenu/AI-ML Modeling.png"),
            route: "/ai-ml-modelling",
          },
          {
            title: "Advanced QA-QC",
            image: require("../../../assets/Images/LeftMenu/advanced QA-QC.png"),
          },
          {
            title: "Dimensionality Reduction",
            image: require("../../../assets/Images/LeftMenu/dimensionality reduction.png"),
          },
          {
            title: "Clustering",
            image: require("../../../assets/Images/LeftMenu/clustering.png"),
          },
          {
            title: "Supervised ML Model",
            image: require("../../../assets/Images/LeftMenu/global ML models.png"),
          },
          {
            title: "Fluidsdata AI Model",
            image: require("../../../assets/Images/LeftMenu/fluidsdata AI model.png"),
            route: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model"
          },
        ],
      },
    ],
    // open: ["exploratory data analysis (EDA)", "Property Prediction"],
  }),
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    toggleIconPosition() {
      return this.drawer ? "right: -12px;" : "left: 0;";
    },
  },
  // watch: {
  //   screenWidth(newWidth) {
  //     if (newWidth < 768) {
  //       this.drawer = false;
  //     } else {
  //       this.drawer = true;
  //     }
  //   },
  // },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawerStateChanged", this.drawer);
    },
    updateMiniVariant(value) {
      this.miniVariant = value;
    },
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 768) {
        this.drawer = false;
      }
      // else if (this.screenWidth > 768 && this.drawer == false) {
      //   this.drawer = false;
      // }
      else {
        this.drawer = true;
      }
    },
    handleKeydown(event) {
      if(event.key === "Escape") {
        this.toggleDrawer();
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", this.handleKeydown);
    this.navigationItems.forEach(item => {
    if (item.route) {
      item.route = item.route.replace(/\s+/g, '-'); // Replacing spaces with hyphens
    }
    if (item.children) {
      item.children.forEach(childItem => {
        if (childItem.route) {
          childItem.route = childItem.route.replace(/\s+/g, '-'); // Replacing spaces with hyphens
        }
      });
    }
  });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>