<template>
  <div class="filters-main-div">
    <v-icon
      class="toggle-icon toggle-icon-right"
      :icon="drawer ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"
      :style="toggleIconPosition"
      @click="toggleDrawer"
    ></v-icon>
    <v-navigation-drawer
      class="fiter-drawer"
      v-model="drawer"
      app
      location="right"
      permanent
      :mini-variant="miniVariant"
      @update:mini-variant="updateMiniVariant"
    >
      <v-row no-gutters class="px-4 pt-5">
        <v-col cols="12" class="pa-0">
          <div class="filter-top-div">
            <div class="filter-heading">Filters</div>
            <div class="reset-icon-div" v-if="isFilterSelected" @click="resetSelectedFilter">
              <v-icon>mdi-close-circle-outline</v-icon>
              <p>Reset</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="pt-2">
          <div class="new-filter-text">Create new filter</div>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Filter</label>
          <v-select
            v-model="selectedFilter"
            placeholder="Select saved filter"
            :items="filteredfilters.map((filter) => filter.name)"
            @update:modelValue="onSelectFilter"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Filter name</label>
          <v-text-field
            v-model="filterName"
            required
            placeholder="Enter filter name"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Asset(s)</label>
          <v-select
            v-model="selectedAsset"
            multiple
            chips
            clearable
            closable-chips
            placeholder="Select an asset"
            :items="filteredAssets.map((asset) => asset.name)"
            :return-object="false"
            class="custom-y-axis-chips"
            @update:modelValue="onSelectAsset"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Field(s)</label>
          <v-select
            v-model="selectedField"
            multiple
            chips
            clearable
            closable-chips
            placeholder="Select a field"
            :items="filteredFields.map((field) => field.name)"
            :return-object="false"
            class="custom-y-axis-chips"
            @update:modelValue="onSelectField"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Reservoir(s)</label>
          <v-select
            v-model="selectedReservoir"
            :items="filteredReservoir.map((reservoir) => reservoir.name)"
            multiple
            chips
            clearable
            closable-chips
            placeholder="Select reservoir"
            item-title="displayName"
            item-value="name"
            :return-object="false"
            class="custom-y-axis-chips"
            @update:modelValue="onSelectReservoir"
          >
          </v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Well(s)</label>
          <v-select
            :items="filteredWells.map((well) => well.name)"
            multiple
            chips
            clearable
            closable-chips
            placeholder="Select well"
            item-title="displayName"
            item-value="name"
            :return-object="false"
            class="custom-y-axis-chips"
            v-model="selectedWell"
            @update:modelValue="onSelectWell"
          >
          </v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Minimum Date</label>
          <VueDatePicker
            v-model="minDate"
            placeholder="Select minimum date"
            :enable-time-picker="false"
            :disabled-dates="disableFutureDates"
          />
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Maximum Date</label>
          <VueDatePicker
            v-model="maxDate"
            placeholder="Select maximum date"
            :enable-time-picker="false"
            :disabled-dates="disableFutureDates"
          />
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Select Sample Kind(s)</label>
          <v-select
            v-model="selectedSampleKind"
            :items="sampleKinds"
            multiple
            chips
            clearable
            closable-chips
            placeholder="Select sample kind"
            item-title="displayName"
            item-value="name"
            :return-object="false"
            class="custom-y-axis-chips"
          >
          </v-select>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Minimum Depth</label>
          <v-text-field
            v-model="minDepth"
            placeholder="Enter minimum depth"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Maximum Depth</label>
          <v-text-field
            v-model="maxDepth"
            placeholder="Enter maximum depth"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Mininum Formation Pressure</label>
          <v-text-field
            v-model="minFormationPressure"
            placeholder="Enter minimum formation pressure"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Maximum Formation Pressure</label>
          <v-text-field
            v-model="maxFormationPressure"
            placeholder="Enter maximum formation pressure"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Minimum Formation Temperature</label>
          <v-text-field
            v-model="minFormationTemperature"
            placeholder="Enter minimum formation temperature"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Maximum Formation Temperature</label>
          <v-text-field
            v-model="maxFormationTemperature"
            placeholder="Enter maximum formation temperature"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Fluid Sample ID</label>
          <vue3-tags-input :tags="fluidSampleID"
                   placeholder="Select fluid sample id"
                   @on-tags-changed="handleChangeTag"/>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Fluid Sample Container ID</label>
          <vue3-tags-input :tags="fluidSampleContainerID"
                   placeholder="Select fluid sample container id"
                   @on-tags-changed="handleChangeTagContainer"/>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Max Samples</label>
          <v-text-field
            v-model="maxSamples"
            placeholder="Enter Samples"
            type="number"
            :rules="numberRules"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-2">
          <label for="">Source</label>
          <v-text-field
            v-model="source"
            placeholder="Enter source"
            variant="outlined"
          ></v-text-field>
        </v-col>
        <template v-if="!isFilterSelected">
          <v-col cols="6" class="pt-2">
            <v-btn
              block
              flat
              class="save-btn text-none"
              @click="saveFilter"
              :loading="createLoading"
              :disabled="isSaveDisabled"
              ><span>Save</span></v-btn
            >
          </v-col>
          <v-col cols="6" class="pr-0 pl-2 py-2">
            <v-btn
              @click="resetFilter"
              block
              flat
              class="reset-btn mr-1 text-none"
              >Reset</v-btn
            >
          </v-col>
        </template>
        <template v-else>
          <v-col cols="6" class="pt-2">
            <v-btn
              block
              flat
              class="save-btn text-none"
              @click="updateFilter"
              :loading="updateLoading"
              :disabled="isUpdateDisabled"
              ><span>Update</span></v-btn
            >
          </v-col>
          <v-col cols="6" class="pr-0 pl-2 py-2">
            <v-btn
              @click="showDeleteConfirmation"
              block
              flat
              class="delete-btn mr-1 text-none"
              >Delete</v-btn
            >
          </v-col>
        </template>
      </v-row>
    </v-navigation-drawer>

    <v-dialog
      transition="dialog-top-transition"
      v-model="confirmDeleteDialog"
      max-width="600"
    >
      <v-alert
        title="Delete Filter"
        text="Are you sure you want to delete filter ?"
      >
        <v-btn variant="tonal" @click="closeDeleteConfirmation">Cancel</v-btn>
        <v-btn color="error" text @click="deleteFilter" :loading="deleteLoading"
          >Delete</v-btn
        >
      </v-alert>
    </v-dialog>

      <v-dialog v-model="showDefaultDialog" transition="dialog-top-transition"
      max-width="600">
       <v-alert
        title="Default Filter"
        text="Do you want to make this filter as a default ?"
      >
        <v-btn variant="tonal" @click="showDefaultDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="confirmDefaultFilter" :loading="defaultLoading"
          >Save</v-btn
        >
      </v-alert>
    </v-dialog>

    <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
      >
        {{ snackbar.message }}
      </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../../axiosInterceptor";
import Vue3TagsInput from 'vue3-tags-input';

export default {
  name: "Filters",
  components: {
    Vue3TagsInput
  },
  data: () => ({
    drawer: false,
    miniVariant: false,
    filters: [],
    filterID: null,
    filterName: null,
    selectedFilter: null,
    filterData: {},
    assets: [],
    selectedAsset: [],
    previousSelectedAssets: [],
    fields: [],
    selectedField: [],
    previousSelectedFields: [],
    wells: [],
    selectedWell: [],
    previousSelectedWells: [],
    reservoirs: [],
    selectedReservoir: [],
    previousSelectedReservoir: [],
    minDate: null,
    maxDate: null,
    sampleKinds: [
      "bhs samples",
      "blend-gas",
      "blend-liquid",
      "brine",
      "condensate",
      "filtrate",
      "gas",
      "gas-dry",
      "mud filtrate",
      "mud sample",
      "oil & water",
      "oil-base",
      "oil-black",
      "oil-dead",
      "oil-heavy",
      "oil-unknown",
      "oil-volatile",
      "other",
      "recomb-fluid",
      "recomb-gas",
      "rinse-post",
      "rinse-pre",
      "solid",
      "sto",
      "toluene",
      "water",
      "water/condensate",
      "synthetic",
      "separator water",
      "separator oil",
      "separator gas",
      "downhole cased",
      "downhole open",
      "recombined",
      "wellhead",
      "commingled",
    ],
    selectedSampleKind: [],
    minDepth: null,
    maxDepth: null,
    minFormationPressure: null,
    maxFormationPressure: null,
    minFormationTemperature: null,
    maxFormationTemperature: null,
    fluidSampleID: [],
    fluidSampleContainerID: [],
    maxSamples: null,
    source: null,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    confirmDeleteDialog: false,
    numberRules: [
      (v) => !!v || "Number is required",
      (v) => (v && v > 0) || "Number must be positive",
    ],
    snackbar: {
      visible: false,
      message: "",
      color: "success",
      timeout: 3000,
    },
    showDefaultDialog: false,
    defaultLoading: false,
  }),
  props: {
    selectedType: {
      type: String
    }
  },
  computed: {
    toggleIconPosition() {
      return this.drawer ? "right: 317px;" : "right: 0;";
    },
    filteredfilters() {
      return this.filters.filter((filter) => filter.name);
    },
    filteredAssets() {
      return this.assets.filter((asset) => asset.name);
    },
    filteredFields() {
      return this.fields.filter((field) => field.name);
    },
    filteredWells() {
      return this.wells.filter((well) => well.name);
    },
    filteredReservoir() {
      return this.reservoirs.filter((reservoir) => reservoir.name);
    },
    ...mapGetters("tenantId", ["getUser"]),
    userFullName() {
      if (this.getUser && this.getUser.firstName && this.getUser.lastName) {
        return `${this.getUser.firstName} ${this.getUser.lastName}`;
      }
    },
    isFilterSelected() {
      return this.selectedFilter !== null;
    },
    // isSaveDisabled() {
    //   return (
    //     !this.filterName ||
    //     (this.selectedAsset.length === 0 &&
    //       this.selectedField.length === 0 &&
    //       this.selectedReservoir.length === 0 &&
    //       this.selectedWell.length === 0 &&
    //       !this.minDate &&
    //       !this.maxDate &&
    //       this.selectedSampleKind.length === 0 &&
    //       !this.minDepth &&
    //       !this.maxDepth &&
    //       !this.minFormationPressure &&
    //       !this.maxFormationPressure &&
    //       !this.minFormationTemperature &&
    //       !this.maxFormationTemperature &&
    //       this.fluidSampleID.length === 0 &&
    //       this.fluidSampleContainerID.length === 0 &&
    //       !this.maxSamples &&
    //       !this.source)
    //   );
    // },
    isSaveDisabled() {
  const hasFilterValue =
    this.filterName &&
    this.selectedAsset.length > 0 ||
    this.selectedField.length > 0 ||
    this.selectedReservoir.length > 0 ||
    this.selectedWell.length > 0 ||
    this.minDate ||
    this.maxDate ||
    this.selectedSampleKind.length > 0 ||
    this.minDepth ||
    this.maxDepth ||
    this.minFormationPressure ||
    this.maxFormationPressure ||
    this.minFormationTemperature ||
    this.maxFormationTemperature ||
    this.fluidSampleID.length > 0 ||
    this.fluidSampleContainerID.length > 0 ||
    this.maxSamples ||
    this.source;

  const invalidNumberValues =
    (this.minDepth && this.numberRules.some(rule => rule(this.minDepth) !== true)) ||
    (this.maxDepth && this.numberRules.some(rule => rule(this.maxDepth) !== true)) ||
    (this.minFormationPressure && this.numberRules.some(rule => rule(this.minFormationPressure) !== true)) ||
    (this.maxFormationPressure && this.numberRules.some(rule => rule(this.maxFormationPressure) !== true)) ||
    (this.minFormationTemperature && this.numberRules.some(rule => rule(this.minFormationTemperature) !== true)) ||
    (this.maxFormationTemperature && this.numberRules.some(rule => rule(this.maxFormationTemperature) !== true)) ||
    (this.maxSamples && this.numberRules.some(rule => rule(this.maxSamples) !== true));

  return !hasFilterValue || invalidNumberValues;
},
    // isUpdateDisabled() {
    //   return (
    //     !this.filterName ||
    //     (this.selectedAsset.length === 0 &&
    //       this.selectedField.length === 0 &&
    //       this.selectedReservoir.length === 0 &&
    //       this.selectedWell.length === 0 &&
    //       !this.minDate &&
    //       !this.maxDate &&
    //       this.selectedSampleKind.length === 0 &&
    //       !this.minDepth &&
    //       !this.maxDepth &&
    //       !this.minFormationPressure &&
    //       !this.maxFormationPressure &&
    //       !this.minFormationTemperature &&
    //       !this.maxFormationTemperature &&
    //       this.fluidSampleID.length === 0 &&
    //       this.fluidSampleContainerID.length === 0 &&
    //       !this.maxSamples &&
    //       !this.source)
    //   );
    // },
    isUpdateDisabled() {
  const hasFilterValue =
    this.filterName &&
    this.selectedAsset.length > 0 ||
    this.selectedField.length > 0 ||
    this.selectedReservoir.length > 0 ||
    this.selectedWell.length > 0 ||
    this.minDate ||
    this.maxDate ||
    this.selectedSampleKind.length > 0 ||
    this.minDepth ||
    this.maxDepth ||
    this.minFormationPressure ||
    this.maxFormationPressure ||
    this.minFormationTemperature ||
    this.maxFormationTemperature ||
    this.fluidSampleID.length > 0 ||
    this.fluidSampleContainerID.length > 0 ||
    this.maxSamples ||
    this.source;

  const invalidNumberValues =
    (this.minDepth && this.numberRules.some(rule => rule(this.minDepth) !== true)) ||
    (this.maxDepth && this.numberRules.some(rule => rule(this.maxDepth) !== true)) ||
    (this.minFormationPressure && this.numberRules.some(rule => rule(this.minFormationPressure) !== true)) ||
    (this.maxFormationPressure && this.numberRules.some(rule => rule(this.maxFormationPressure) !== true)) ||
    (this.minFormationTemperature && this.numberRules.some(rule => rule(this.minFormationTemperature) !== true)) ||
    (this.maxFormationTemperature && this.numberRules.some(rule => rule(this.maxFormationTemperature) !== true)) ||
    (this.maxSamples && this.numberRules.some(rule => rule(this.maxSamples) !== true));

  return !hasFilterValue || invalidNumberValues;
},
  },
  methods: {
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
     handleChangeTag(tags) {
      this.fluidSampleID = tags;
    },
    handleChangeTagContainer(tags) {
      this.fluidSampleContainerID = tags;
    },
    disableFutureDates(date) {
      const today = new Date();
      return date > today;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawerStateChanged", this.drawer);
    },
    updateMiniVariant(value) {
      this.miniVariant = value;
    },
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 768) {
        this.drawer = false;
      } else {
        this.drawer = false;
      }
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.toggleDrawer();
      }
    },
    getAllfilters() {
      this.timeoutMessage = "";
      api
        .get("public/api/v1/filters")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.filters = response.data.data.filters;
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        });
    },
    getAllAssets() {
      this.timeoutMessage = "";
      api
        .get("public/api/v1/locations/assets")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.assets = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {});
    },
    fetchField(assets) {
      const assetString = JSON.stringify(assets);
      api
        .get(
          "public/api/v1/locations/fields?asset=" +
            encodeURIComponent(assetString)
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.fields = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },
    fetchReservoir(fieldName) {
      const fieldString = JSON.stringify(fieldName);
      api
        .get(
          "public/api/v1/locations/reservoirs?field=" +
            encodeURIComponent(fieldString)
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.reservoirs = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },
    fetchWell(fieldName) {
      const fieldString = JSON.stringify(fieldName);
      api
        .get(
          "public/api/v1/locations/wells?field=" +
            encodeURIComponent(fieldString)
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.wells = response.data.data.locations.sort((a, b) => {
              return (
                parseInt(a.name.split("-")[1]) - parseInt(b.name.split("-")[1])
              );
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        });
    },
    onSelectAsset(selectedAssets) {
      this.previousSelectedAssets = [...selectedAssets];

      // Fetch fields for all selected assets
      this.fetchField(selectedAssets);
    },
    onSelectField(selectedFields) {
      this.previousSelectedFields = [...selectedFields];

      // Fetch Wells for all selected fields
      this.fetchWell(selectedFields);
      this.fetchReservoir(selectedFields);
    },
    onSelectWell(selectedWells) {
      this.previousSelectedWells = [...selectedWells];
    },
    onSelectReservoir(selectedReservoirs) {
      this.previousSelectedReservoir = [...selectedReservoirs];
    },
    async onSelectFilter(selectedFilterName) {
      // Find the selected filter object by name
      const selectedFilter = this.filters.find(
        (filter) => filter.name === selectedFilterName
      );

      if (selectedFilter) {
        try {
          // Example API call to fetch filter details
          const response = await api.get(
            `public/api/v1/filters/${selectedFilter.ID}`
          );
          const filterData = response.data.data.filter; // Adjust this according to your API response structure
          const type = this.selectedType;
          this.selectedFilter = filterData.name;
          this.filterID = filterData.ID;
          this.populateFieldsFromFilter(filterData);
          if(type) {
            this.$emit('filter-data', { filterData, type });
            const defaultFilterID = localStorage.getItem("defaultFilter");
            if(selectedFilter.ID !== defaultFilterID) {
              this.showDefaultDialog = true;
            }
          }
          else {
            this.showSnackbar("Please select chart first.", "error");
          this.resetFilter()
          }
        } catch (error) {
          console.error("Error fetching filter details:", error);
        }
      }
    },

    async defaultFilterData() {
      const getDefaultFilterID = localStorage.getItem("defaultFilter");
      const selectSavedChart = localStorage.getItem("savedChartData")

      if (getDefaultFilterID && selectSavedChart) {
        try {
          // Example API call to fetch filter details
          const response = await api.get(
            "public/api/v1/filters/default"
          );
          const filterData = response.data.data.filter; // Adjust this according to your API response structure
          this.selectedFilter = filterData.name;
          this.populateFieldsFromFilter(filterData);
          if(type) {
            this.$emit('filter-data', { filterData });
          }
          else {
            this.showSnackbar("Please select chart first.", "error");
          this.resetFilter()
          }
        } catch (error) {
          console.error("Error fetching filter details:", error);
        }
      }
    },

    formatDate() {
      const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
      };

      if (this.minDate && isValidDate(this.minDate)) {
        this.minDate = new Date(this.minDate).toISOString().split("T")[0];
      } else {
        this.minDate = null; // or some default value
      }

      if (this.maxDate && isValidDate(this.maxDate)) {
        this.maxDate = new Date(this.maxDate).toISOString().split("T")[0];
      } else {
        this.maxDate = null; // or some default value
      }

      return {
        min: this.minDate,
        max: this.maxDate,
      };
    },

    formatNumber(value) {
      return value ? parseFloat(value) : null;
    },
    resetSelectedFilter() {
      localStorage.removeItem("selectedFilterData")
      this.resetFilter()
    },
    resetFilter() {
      this.selectedFilter = null;
      this.filterName = null;
      this.selectedAsset = [];
      this.previousSelectedAssets = [];
      this.selectedField = [];
      this.previousSelectedFields = [];
      this.selectedWell = [];
      this.previousSelectedWells = [];
      this.selectedReservoir = [];
      this.previousSelectedReservoir = [];
      this.minDate = null;
      this.maxDate = null;
      this.selectedSampleKind = [];
      this.minDepth = null;
      this.maxDepth = null;
      this.minFormationPressure = null;
      this.maxFormationPressure = null;
      this.minFormationTemperature = null;
      this.maxFormationTemperature = null;
      this.fluidSampleID = [];  // Reset fluidSampleID
    this.fluidSampleContainerID = [];
      this.maxSamples = null;
      this.source = null;
    },
    async confirmDefaultFilter() {
      this.defaultLoading = true;
      const defaultFilter = {
        filterID: this.filterID,
        username: this.userFullName
      }
      const requestBody = {
        Data: {
          DefaultFilter: defaultFilter,
        },
      };

      try {
        await api.post("public/api/v1/filters/default", requestBody);
        localStorage.setItem("defaultFilter", requestBody.Data.DefaultFilter.filterID);
        this.defaultLoading = false;
        this.showDefaultDialog = false;
      } catch (error) {
        console.error(error);
        this.defaultLoading = false;
        this.showDefaultDialog = false;
      }
    },
    async saveFilter() {
      this.createLoading = true;
      const maxSamplesNumber = parseInt(this.maxSamples);
      const filter = {
        name: this.filterName,
        asset: this.selectedAsset,
        field: this.selectedField,
        reservoir: this.selectedReservoir,
        well: this.selectedWell,
        sampleDate: this.formatDate(),
        sampleKind: this.selectedSampleKind,
        depth: {
          min: this.formatNumber(this.minDepth),
          max: this.formatNumber(this.maxDepth),
        },
        formationPressure: {
          min: this.formatNumber(this.minFormationPressure),
          max: this.formatNumber(this.maxFormationPressure),
        },
        formationTemperature: {
          min: this.formatNumber(this.minFormationTemperature),
          max: this.formatNumber(this.maxFormationTemperature),
        },
        fluidSampleID: this.fluidSampleID ? this.fluidSampleID : [],
        fluidSampleContainerID: this.fluidSampleContainerID
          ? this.fluidSampleContainerID
          : [],
        maxSamples: maxSamplesNumber,
        source: this.source,
        user: this.userFullName,
        public: true,
      };

      const requestBody = {
        Data: {
          Filter: filter,
        },
      };

      try {
        await api.post("public/api/v1/filters", requestBody);
        this.resetFilter();
        this.fluidSampleID = [];
        this.fluidSampleContainerID = [];
        this.getAllfilters();
        this.createLoading = false;
      } catch (error) {
        const err= error.response.data.error;
        this.showSnackbar(err,"error");
          this.resetFilter()
        this.createLoading = false;
      }
    },
    populateFieldsFromFilter(selectedFilter) {
      this.filterData = selectedFilter;
      this.filterName = selectedFilter.name;
      this.selectedAsset = selectedFilter.asset || [];
      this.selectedField = selectedFilter.field || [];
      this.selectedWell = selectedFilter.well || [];
      this.selectedReservoir = selectedFilter.reservoir || [];
      this.minDate = selectedFilter.sampleDate
        ? new Date(selectedFilter.sampleDate.min)
        : null;
      this.maxDate = selectedFilter.sampleDate
        ? new Date(selectedFilter.sampleDate.max)
        : null;
      this.selectedSampleKind = selectedFilter.sampleKind || [];
      this.minDepth = selectedFilter.depth ? selectedFilter.depth.min : null;
      this.maxDepth = selectedFilter.depth ? selectedFilter.depth.max : null;
      this.minFormationPressure = selectedFilter.formationPressure
        ? selectedFilter.formationPressure.min
        : null;
      this.maxFormationPressure = selectedFilter.formationPressure
        ? selectedFilter.formationPressure.max
        : null;
      this.minFormationTemperature = selectedFilter.formationTemperature
        ? selectedFilter.formationTemperature.min
        : null;
      this.maxFormationTemperature = selectedFilter.formationTemperature
        ? selectedFilter.formationTemperature.max
        : null;
        this.fluidSampleID = selectedFilter.fluidSampleID && selectedFilter.fluidSampleID.length > 0 ? selectedFilter.fluidSampleID : [];
      this.fluidSampleContainerID = selectedFilter.fluidSampleContainerID && selectedFilter.fluidSampleContainerID.length > 0 ? selectedFilter.fluidSampleContainerID : [];
      this.maxSamples = selectedFilter.maxSamples;
      this.source = selectedFilter.source;
    },
    showDeleteConfirmation() {
      this.confirmDeleteDialog = true;
    },
    closeDeleteConfirmation() {
      this.confirmDeleteDialog = false;
    },
    deleteFilter() {
      this.deleteLoading = true;
      if (this.filterData && this.filterData.ID) {
        api
          .delete(`public/api/v1/filters/${this.filterData.ID}`)
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.getAllfilters(); // Refresh filters list
              this.resetFilter(); // Reset selected filter
              this.confirmDeleteDialog = false; // Close the confirmation dialog
            }
          })
          .catch((error) => {
            console.log("Error deleting filter:", error);
          })
          .finally(() => {
            this.deleteLoading = false;
          });
      }
    },
    async updateFilter() {
      this.updateLoading = true;
      const maxSamplesNumber = parseInt(this.maxSamples);
      const filter = {
        name: this.filterName,
        asset: this.selectedAsset,
        field: this.selectedField,
        reservoir: this.selectedReservoir,
        well: this.selectedWell,
        sampleDate: this.formatDate(),
        sampleKind: this.selectedSampleKind,
        depth: {
          min: this.formatNumber(this.minDepth),
          max: this.formatNumber(this.maxDepth),
        },
        formationPressure: {
          min: this.formatNumber(this.minFormationPressure),
          max: this.formatNumber(this.maxFormationPressure),
        },
        formationTemperature: {
          min: this.formatNumber(this.minFormationTemperature),
          max: this.formatNumber(this.maxFormationTemperature),
        },
        fluidSampleID: this.fluidSampleID ? this.fluidSampleID : [],
        fluidSampleContainerID: this.fluidSampleContainerID
          ? this.fluidSampleContainerID
          : [],
        maxSamples: maxSamplesNumber,
        source: this.source,
        user: this.userFullName,
        public: true,
      };

      const requestBody = {
        Data: {
          Filter: filter,
        },
      };

      try {
        await api.put(
          `public/api/v1/filters/${this.filterData.ID}`,
          requestBody
        );
        this.resetFilter();
        this.getAllfilters(); // Refresh filters list
        this.updateLoading = false;
      } catch (error) {
        console.error(error);
        this.updateLoading = false;
      }
    },
  },
  mounted() {
    this.defaultFilterData();
    this.getAllfilters();
    this.getAllAssets();
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>